import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 问卷列表
  surveyList(data) {
    return request({
      url: '/survey_info/surveyList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 问卷详情
  surveyDetail(data) {
    return request({
      url: '/survey_info/surveyDetail?' + parameterChange(data),
      method: 'get',
    });
  },
  // 问卷新增
  surveyAdd(data) {
    return request({
      url: 'survey_info/surveyAdd',
      method: 'post',
      data,
    });
  },
  // 问卷编辑
  surveyUpdate(data) {
    return request({
      url: '/survey_info/surveyUpdate',
      method: 'post',
      data,
    });
  },
  // 问卷删除
  surveyDelete(data) {
    return request({
      url: '/survey_info/surveyDelete',
      method: 'post',
      data,
    });
  },
};
