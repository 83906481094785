<template>
  <div ref="modalRef">
    <a-modal
      :width="width"
      :open="show"
      :footer="null"
      :getContainer="() => $refs.modalRef"
      :afterClose="afterClose"
      :title="`${id ? '编辑' : '新建'}${title}`"
      destroyOnClose
      @cancel="handleClose"
    >
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item
          :class="{ require: showLabel(item) }"
          :label="item.label"
          :key="item.key"
          v-for="item in form"
        >
          <form-item :item="item" @change="(e) => emits('change', e)" @onAdd="emits('onAdd')" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 8 }">
          <a-space>
            <a-button type="primary" @click="onSubmit">确定</a-button>
            <a-button @click="handleClose">取消</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
  
<script setup>
import { formItem } from '@/component';
import { getFormData, getFormValue, judgeSubmit } from '@/utils';
import { cloneDeep } from 'lodash';
import { ref, getCurrentInstance } from 'vue';

const { proxy } = getCurrentInstance();
const props = defineProps({
  width: { type: Number },
  title: { type: String, default: '' },
  idField: { type: String, default: 'id' },
  api: { type: Object, default: () => {} },
  formArr: { type: Array, default: () => [] },
  callback: { type: Function },
});

const modalRef = ref();

const showLabel = (item) => {
  if (item.hasOwnProperty('require') && item.require) {
    return true;
  }
  return false;
};

const id = ref(),
  show = ref(false),
  handleOpen = async (idVal) => {
    form.value = props.formArr;
    show.value = true;
    if (idVal) {
      id.value = idVal;
      await getDetail();
    } else {
      form.value.forEach((item) => {
        if (item.defaultValue) {
          item.value = item.defaultValue;
        }
      });
    }
    emits('getOptions');
  },
  handleClose = () => {
    emits('onClose');
    show.value = false;
  },
  afterClose = () => {
    id.value = undefined;
    form.value = form.value.map((item) => {
      if (item.defaultValue) {
        item.value = item.defaultValue;
      } else {
        if (['image'].includes(item.type)) {
          item.value = [];
        }
        // else if (['inputNumber'].includes(item.type)) {
        //   item.value = 0;
        // }
        else if (['dateTime'].includes(item.type)) {
          item.value = undefined;
          item.valueCn = undefined;
        } else if (['addInput'].includes(item.type)) {
          item.value = [
            {
              index: undefined,
              text: undefined,
              image: {
                value: [],
                path: 'exam/img/quesBank',
                maxCount: 1,
              },
            },
          ];
        } else if (['checkbox', 'radio'].includes(item.type)) {
          item.value = undefined;
          item.options = [];
        } else {
          item.value = undefined;
        }
      }
      return item;
    });
  };
defineExpose({ handleOpen });

const emits = defineEmits(['getList', 'change']);
const form = ref([]),
  getDetail = () => {
    return new Promise(async (resolve) => {
      let res = await proxy.$api[props?.api?.details]({ [props.idField]: id.value });
      if (res) {
        if (props?.callback) {
          res = props.callback()['DETAILS'](res.data);
        }
        form.value.forEach((item) => {
          const { val, valCn } = getFormValue(res.data, item);
          item.value = val;
          if (['date', 'dateTime'].includes(item.type)) {
            item.valueCn = valCn;
          }
        });
      }
      resolve(1);
    });
  },
  onSubmit = () => {
    if (!judgeSubmit(form.value)) {
      return;
    }
    const params = {
      ...getFormData(form.value, props?.callback ? props?.callback()['SUBMIT'] : undefined),
    };

    let api = props.api.add;
    if (id.value) {
      params[props.idField] = id.value;
      api = props.api.update;
    }

    proxy.$api[api](params).then((res) => {
      if (res) {
        proxy.$message.info(res.msg);
        handleClose();
        emits('getList');
      }
    });
  };

const labelCol = { style: { width: '150px' } },
  wrapperCol = { span: 14 };
</script>
  
<style lang="less" scoped>
:deep(.ant-modal) {
  .ant-modal-header {
    margin-bottom: 40px;
  }

  .ant-form-item {
    &.require {
      .ant-form-item-label > label {
        &::before {
          content: '*';
          color: #f5222d;
          font-size: 18px;
          position: absolute;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .ant-input-number {
      width: 100%;
    }
  }
}
</style>
  